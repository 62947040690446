import BannerImage from "../assets/images/OneFifty_Residences/Residences Hero.jpg"
import MobileImage from "../assets/images/OneFifty_Residences/Mobile-image.jpg"

// Studio
import sec1Image1 from "../assets/images/OneFifty_Residences/Studio Kitchen.jpg";
import sec1Image2 from "../assets/images/OneFifty_Residences/Studio Bathroom.jpg";

//One Bed
import sec2Image1 from "../assets/images/OneFifty_Residences/1 Bed Bedroom.jpg";
import sec2Image2 from "../assets/images/OneFifty_Residences/1 Bed Kitchen.jpg";
import sec2Image3 from "../assets/images/OneFifty_Residences/1 Bed Living room.jpg";

//Two Bed
import sec3Image1 from "../assets/images/OneFifty_Residences/2 Bed Kitchen.jpg";
import sec3Image2 from "../assets/images/OneFifty_Residences/2 Bed Living_Room.jpg";
import sec3Image3 from "../assets/images/OneFifty_Residences/2 Bed View.jpg";

//Three Bed

import sec4image1 from '../assets/images/OneFifty_Residences/three-bed-window-view-1.jpg';
import sec4image2 from '../assets/images/OneFifty_Residences/three-bed-kitchen.jpg';
import sec4image3 from '../assets/images/OneFifty_Residences/three-bed-window-view-2.jpg';


//Components
import TwoColumn from "../components/TwoColumn";
import Footer from "../components/Footer";
import Banner from "../components/Hero";
import QuickNav from "../components/QuickNav";

//React Imports
import { Helmet } from "react-helmet";

let QuickLinks = {
    backgroundColor: 'white',
    prev: {
        title: "Home",
        url: "/"
    },
    next: {
        title: "Buildings",
        url: "/building"
    }
}
let footerData = {
    backgroundColor: "white"
}
let BannerData = {
    backgroundImage: BannerImage,
    mobileImage: MobileImage,
    heading: "Step Inside One Fifty…",
    description: "Unlike other apartments for rent in Brooklyn, the apartments at One Fifty offer fresh, contemporary living spaces with a forward thinking point of view. Arched interior details subtly elevate each unit’s fresh, crisp design aesthetic. Complete with Stainless Steel appliances, hardwood flooring, and marble accents, the residences at One Fifty are ready to take your lifestyle to a whole new level.",
    color : "brown",
}

const twoCoulmnData = [
    {
        variant: "brown",
        heading: "Studio",
        description: "Feeling cramped is never an issue at these Brooklyn studio apartments. Contemporary design elements like soft, neutral colors and hardwood flooring combine to make each studio unit at One Fifty feel even more open. Generous kitchens and designer bathrooms make these Studio apartments feel more like an upscale retreat.",
        images: [sec1Image1, sec1Image2],
        flexDirection: "row",
        hasPricing : true,
        prices : "3,050",
        type : "studio"
    },
    {
        variant: "white-brown",
        heading: "One Bedroom",
        description: "One Fifty offers the one bedroom Brooklyn apartment you’ve been searching for. Clean and contemporary in design, every unit features a spacious open-concept floorplan with a generous kitchen island for extra seating. Hardwood flooring highlights the bright, airy aesthetic, while on-trend touches like Stainless Steel appliances and marble backsplashes make them feel even more luxurious.",
        images: [sec2Image1, sec2Image2, sec2Image3],
        flexDirection: "row-reverse",
        hasPricing : true,
        prices : "3,900",
        type : "1-bed"
    },
    {
        variant: "brown",
        heading: "Two Bedroom",
        description: "The large two-bedroom apartments for rent at One Fifty will redefine the way you think about life in the city. Perfect for anyone wanting an extra bedroom or a designated home office, these expansive floorplans offer generous closets for storage, designer fixtures, and luxurious finishes. In the designer kitchens, large islands offer extra seating while Stainless Steel appliances and marble backsplashes further elevate the upscale ambiance.",
        images: [sec3Image1, sec3Image2, sec3Image3],
        flexDirection: "row",
        hasPricing : true,
        prices : "4,850",
        type : "2-bed"
    },
    {
        variant: "white-brown",
        heading: "Three Bedroom",
        description: "Experience unparalleled luxury in the spacious three-bedroom apartments at One Fifty. Ideal for families, roommates, or those needing extra space, these thoughtfully designed floorplans provide ample room for living, working, and entertaining. Each apartment features expansive closets, designer fixtures, and high-end finishes throughout. The gourmet kitchens are equipped with large islands for additional seating, complemented by Stainless Steel appliances and elegant marble backsplashes, creating a sophisticated culinary environment. Enjoy the perfect blend of comfort and style in your new home at One Fifty.",
        images: [sec4image1,sec4image2,sec4image3,],
        flexDirection: "row-reverse",
        hasPricing : true,
        prices : "6,700",
        type : "3-bed"
    },
]

export default function Residences() {
    return (
        <>
            <Helmet>
                <title>Studio, One Bedroom, & Two Bedroom Bushwick Apartments - EXR</title>
                <meta name="description" content="The residences at One Fifty offer large Bushwick luxury rentals with Stainless Steel appliances, hardwood flooring, marble accents, and more."/>
            </Helmet>
            <Banner data={BannerData} />
            {
                twoCoulmnData.map((section, index) => {
                    return <TwoColumn key={index} data={section} />;
                })
            }
            <QuickNav links={QuickLinks} />
            <Footer data={footerData} />
        </>
    )
}