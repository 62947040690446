import BannerImage from "../assets/images/OneFifty_HomePage_v1/150_EXT_3_FFF.jpg"
import mobileImage from "../assets/images/OneFifty_HomePage_v1/Mobile-image.png"
//Home First Block
import sec1Image1 from "../assets/images/OneFifty_HomePage_v1/Home-First-Block-img1.jpg";
import sec1Image2 from "../assets/images/OneFifty_HomePage_v1/Home-First-Block-img2.jpg";
import sec1Image3 from "../assets/images/OneFifty_HomePage_v1/Home-First-Block-Img3.jpg";

//Elevate Your Everyday
import sec2Image1 from "../assets/images/OneFifty_HomePage_v1/One_Fifty_Section_2_SliderImage_1.jpg";
import sec2Image2 from "../assets/images/OneFifty_HomePage_v1/One_Fifty_Section_2_SliderImage_2.jpg";

//Expand Your Horizons
import sec3Image1 from "../assets/images/OneFifty_HomePage_v1/One_Fifty_Section_3_SliderImage_1.jpg";
import sec3Image2 from "../assets/images/OneFifty_HomePage_v1/One_Fifty_Section_3_SliderImage_2.jpg";
import sec3Image3 from "../assets/images/OneFifty_HomePage_v1/One_Fifty_Section_3_SliderImage_3.jpg";

//Components
import TwoColumn from "../components/TwoColumn";
import Banner from "../components/Hero";
import Table from "../components/Table";
import Footer from "../components/Footer";
import QuickNav from "../components/QuickNav";

//React Imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


let QuickLinks = {
  backgroundColor: "white",
  prev: {
    title: "Contact",
    url: "/contact"
  },
  next: {
    title: "Residences",
    url: "/residences"
  }
}
let footerData = {
  backgroundColor: "white"
}


let BannerData = {
  backgroundImage: BannerImage,
  mobileImage: mobileImage,
  backgroundColor: "normal",
  heading: "Apartment Rentals <span>in</span> the Heart of Bushwick",
  description: "Welcome to One Fifty, one of Bushwick's newest and premier addresses. Located at 150 Noll Street in Bushwick, Brooklyn, One Fifty's state-of-the-art Bushwick apartments include premium finishes and high-end amenities.",
  color: "white-orange",
}
const twoCoulmnData = [
  {
    variant: "white-orange",
    heading: "Escape The Ordinary",
    description: "Every apartment at One Fifty Brooklyn is ready to take your breath away. Clean, bright, and airy, these upscale apartments feature crisp, contemporary design elements that come together to create a modern-day escape from the ordinary. ",
    images: [sec1Image1, sec1Image2, sec1Image3],
    flexDirection: "row",
    cta: "/residences",
    hasPricing : false,
    subHeading : "residences"
  },
  {
    variant: "brown",
    heading: "Elevate Your Everyday",
    description: "We believe your living space should elevate every aspect of your life. That’s why One Fifty offers incredible amenities like an on-site fitness center with yoga studio, a billiard room, and a rooftop screening area for watching movies on warm summer nights.",
    images: [sec2Image1, sec2Image2],
    flexDirection: "row-reverse",
    cta: "/building",
    hasPricing : false,
    subHeading : "building"
  },
  {
    variant: "green",
    heading: "Expand Your Horizons",
    description: "You’ll be surrounded by excitement in Bushwick – one of Brooklyn’s most eclectic and authentically New York neighborhoods. Delicious eateries, local hot-spots, live music venues, and easy access to public transportation make this area one of Brooklyn’s finest.",
    images: [sec3Image1, sec3Image2, sec3Image3],
    flexDirection: "row",
    cta: "/neighborhood",
    hasPricing : false,
    subHeading : "neighborhood"
  },
]

export default function Home() {

  const scrollUp = () => {
    window.scrollTo({ top: 0 });
  }

  return (
    <>
      <Helmet>
        <title>One Fifty | Luxury Bushwick Apartments at 150 Noll Street - EXR</title>
        <meta name="description" content="Located at 150 Noll Street, One Fifty offers luxury apartments for rent in Bushwick, Brooklyn – an excellent section of the New York City. " />
      </Helmet>
      <Banner data={BannerData} />

      {
        twoCoulmnData.map((section, index) => {
          return <TwoColumn key={index} data={section} />;
        })
      }
      <Table />
      <div className="cta-wrap">
        <Link className="cta" to="/availabilities" onClick={scrollUp}>
          Learn More
        </Link>
      </div>
      <QuickNav links={QuickLinks} />
      <Footer data={footerData} />
    </>
  );
}